import React, { useEffect, useState, createRef } from 'react'
import Button from 'components/button'
import api from 'helpers/api'
import { useProject } from 'hooks/project'
import { SketchPicker, CompactPicker } from 'react-color'
import useApplicationStore from 'hooks/application'
import FormattingBox from 'views/settings/components/formatting/components/box'
import { IHeadingStyle } from 'types'
import { PROJECT } from 'app-constants'
import { capitaliseFirstLetter } from 'helpers/utils'
import { Delete } from 'components/icons'
import Flag from 'components/flag'
import constants from 'style/constants.module.scss'

const Formatting: React.FC = () => {
  const { project, updateProject, projectOwner } = useProject()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()

  const [savingInfo, setSavingInfo] = useState<boolean>(false)

  const [editColour, setEditColour] = useState<{ editing: boolean; colour: string }>({
    editing: false,
    colour: project.primaryColour
  })

  const bannerFile = createRef<HTMLInputElement>()
  const wordTemplateFile = createRef<HTMLInputElement>()

  const [bannerSrc, setBannerSrc] = useState<string>()
  const [bannerReady, setBannerReady] = useState<boolean>(false)

  const [wordTemplateSrc, setWordTemplateSrc] = useState<string>()
  const [wordTemplateReady, setWordTemplateReady] = useState<boolean>(false)

  const onBannerClick = () => {
    if (bannerFile.current) {
      bannerFile.current.click()
    }
  }

  const onWordTemplateClick = () => {
    if (wordTemplateFile.current) {
      wordTemplateFile.current.click()
    }
  }

  const onDefaultBannerChange = async (e: any) => {
    if (
      project &&
      window.confirm(
        'Are you sure you want to update the default banner image? This will set the banner image at the top of all documents.'
      )
    ) {
      const file = e.target.files[0]
      e.target.value = ''

      try {
        const response = await api.uploadFile(file)
        saveProjectInfo('defaultBanner', response.data.url)
      } catch (e) {
        setSnackbarMessage({
          status: 'error',
          message: 'Error updating project default banner, file type might not be supported'
        })
      }
    }
  }

  const onWordTemplateChange = async (e: any) => {
    if (
      project &&
      window.confirm(
        'Are you sure you want to update the default word template? This will set the default word template for all documents.'
      )
    ) {
      const file = e.target.files[0]
      e.target.value = ''

      try {
        const response = await api.uploadTemplateFile(project.publicId, file)
        const newProjectInfo = response.data
        updateProject({ ...project, wordTemplate: newProjectInfo.wordTemplate })
        setSavingInfo(false)
        setSnackbarMessage({
          status: 'success',
          message: `${capitaliseFirstLetter(PROJECT)} word template updated successfully`
        })
      } catch (e) {
        displayErrorMessage(e)
      }
    }
  }

  const signBannerUrl = async () => {
    if (project && project.defaultBanner) {
      const signedFileTokenResponse = await api.signUrl(project.defaultBanner)
      setBannerSrc(signedFileTokenResponse.data.url)
      setBannerReady(true)
    }
  }

  const signWordTemplateUrl = async () => {
    if (project && project.wordTemplate) {
      const signedFileTokenResponse = await api.signUrl(project.wordTemplate)
      setWordTemplateSrc(signedFileTokenResponse.data.url)
      setWordTemplateReady(true)
    }
  }

  useEffect(() => {
    if (project && project.defaultBanner) {
      signBannerUrl()
    }

    if (project && project.wordTemplate) {
      signWordTemplateUrl()
    }
  }, [project && project.defaultBanner, project && project.wordTemplate])

  const saveProjectInfo = (
    field:
      | 'primaryColour'
      | 'defaultBanner'
      | 'defaultHeaderBackgroundColor'
      | 'defaultHeaderTextColor'
      | 'wordTemplate',
    value: string | null
  ) => {
    setSavingInfo(true)
    const newProjectInfo = { ...project }
    if (field === 'primaryColour' && value !== null) newProjectInfo['primaryColour'] = value
    else if (field === 'defaultBanner') newProjectInfo['defaultBanner'] = value
    else if (field === 'defaultHeaderBackgroundColor' && value !== null)
      newProjectInfo['defaultHeaderBackgroundColor'] = value
    else if (field === 'defaultHeaderTextColor' && value !== null) newProjectInfo['defaultHeaderTextColor'] = value
    else if (field === 'wordTemplate') newProjectInfo['wordTemplate'] = value
    api({
      method: 'PUT',
      endpoint: `/project/${project.publicId}`,
      data: {
        primaryColour: newProjectInfo.primaryColour,
        defaultBanner: newProjectInfo.defaultBanner,
        defaultHeaderBackgroundColor: newProjectInfo.defaultHeaderBackgroundColor,
        defaultHeaderTextColor: newProjectInfo.defaultHeaderTextColor,
        wordTemplate: newProjectInfo.wordTemplate
      }
    })
      .then(() => {
        updateProject(newProjectInfo)
        setEditColour({ editing: false, colour: newProjectInfo.primaryColour })
        setSavingInfo(false)
        setSnackbarMessage({
          status: 'success',
          message: `${capitaliseFirstLetter(PROJECT)} information updated successfully`
        })
      })
      .catch((error) => {
        setSavingInfo(false)
        displayErrorMessage(error)
      })
  }

  const updateProjectFormatting = (formatting: IHeadingStyle) => {
    const newProjectInfo = { ...project }
    newProjectInfo.processTitleBold = formatting.bold
    newProjectInfo.processTitleItalic = formatting.italic
    newProjectInfo.processTitleUnderline = formatting.underline
    newProjectInfo.processTitleColour = formatting.colour
    newProjectInfo.processTitleFontSize = formatting.fontSize
    newProjectInfo.processTitleAlignment = formatting.alignment
      ? formatting.alignment
      : newProjectInfo.processTitleAlignment

    api({
      method: 'PUT',
      endpoint: `/project/${project.publicId}`,
      data: {
        processTitleFontSize: formatting.fontSize,
        processTitleBold: formatting.bold,
        processTitleItalic: formatting.italic,
        processTitleUnderline: formatting.underline,
        processTitleColour: formatting.colour,
        processTitleAlignment: newProjectInfo.processTitleAlignment
      }
    })
      .then(() => {
        updateProject(newProjectInfo)
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }

  const addHeadingLevel = () => {
    api({
      method: 'POST',
      endpoint: `/project/${project.publicId}/add_heading_styling`
    })
      .then((data) => {
        updateProject({ ...project, headingStyles: data.data.headingStyles })
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }

  const updateStyle = (newFormatting: IHeadingStyle) => {
    if (newFormatting.publicId) {
      api({
        method: 'POST',
        endpoint: `/project/${project.publicId}/style/${newFormatting.publicId}`,
        data: {
          fontSize: newFormatting.fontSize,
          bold: newFormatting.bold,
          italic: newFormatting.italic,
          underline: newFormatting.underline,
          colour: newFormatting.colour,
          numberingStyle: newFormatting.numberingStyle,
          startAt0: newFormatting.startAt0
        }
      }).then((data) => {
        updateProject({ ...project, headingStyles: data.data.headingStyles })
      })
    }
  }

  const removeTopStyle = () => {
    api({
      method: 'DELETE',
      endpoint: `/project/${project.publicId}/delete_top_style`
    }).then((data) => {
      updateProject({ ...project, headingStyles: data.data.headingStyles })
    })
  }

  const onRemoveDefaultBanner = () => {
    if (window.confirm('Are you sure you want to remove the default banner image?')) {
      saveProjectInfo('defaultBanner', null)
    }
  }

  const onRemoveWordTemplate = () => {
    if (window.confirm('Are you sure you want to remove the default word template?')) {
      saveProjectInfo('wordTemplate', null)
    }
  }

  const bulkApplyColumnColouring = () => {
    if (
      window.confirm(
        'Are you sure you want to apply the selected background and text colours to all columns in the hub? This will replace any existing colours for all columns in all tables in this hub.'
      )
    ) {
      api({
        method: 'POST',
        endpoint: `/project/${project.publicId}/set-column-coloring`
      })
        .then(() => {
          setSnackbarMessage({
            status: 'success',
            message: 'Column colouring applied to all columns'
          })
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    }
  }

  return (
    <div className="w-full" style={{ padding: '10px 30px' }}>
      <div className="mb-30px">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          {capitaliseFirstLetter(PROJECT)} Colours
        </div>
        <div className="mb-15px">
          This is the primary colour that is used to style your {capitaliseFirstLetter(PROJECT)} and all exported
          documents. The sidebar will appear in this colour, as well as buttons throughout the{' '}
          {capitaliseFirstLetter(PROJECT)}.
        </div>
        <div className="flex items-center">
          {editColour.editing && projectOwner && (
            <SketchPicker
              color={editColour.colour}
              onChange={(value: any) => setEditColour({ ...editColour, colour: value.hex })}
            />
          )}
          {!editColour.editing && (
            <div className="flex items-center justify-center">
              <div
                className="rounded"
                style={{
                  width: '20px',
                  height: '20px',
                  background: project.primaryColour,
                  marginRight: '5px'
                }}
              ></div>
              <div>{project.primaryColour}</div>
            </div>
          )}
          {!editColour.editing && projectOwner && (
            <Button
              internalType="outline"
              onClick={() => setEditColour({ ...editColour, editing: true })}
              disabled={savingInfo}
              style={{ marginLeft: '20px', maxWidth: '50px' }}
            >
              ✎
            </Button>
          )}
          {editColour.editing && projectOwner && (
            <Button
              internalType="danger"
              style={{ marginLeft: '15px' }}
              disabled={savingInfo}
              onClick={() =>
                setEditColour({
                  colour: project.primaryColour,
                  editing: false
                })
              }
            >
              Cancel
            </Button>
          )}
          {editColour.editing && projectOwner && (
            <Button
              style={{ marginLeft: '15px' }}
              onClick={() => saveProjectInfo('primaryColour', editColour.colour)}
              isLoading={savingInfo}
            >
              Save
            </Button>
          )}
        </div>
      </div>

      <div className="mb-30px">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          <div className="flex items-center">
            Document Export Word Template
            <Flag text="Beta" color={constants.red} />
          </div>
          <Button
            internalType={project.wordTemplate === null ? 'accept' : 'outline'}
            onClick={() => onWordTemplateClick()}
            style={{ marginLeft: 'auto' }}
          >
            {project.wordTemplate === null ? '+ Add Word Template' : 'Change Word Template'}
          </Button>
          {project.wordTemplate !== null && (
            <Button internalType="danger" onClick={() => onRemoveWordTemplate()} style={{ marginLeft: '10px' }}>
              <Delete />
              <span style={{ marginLeft: '5px' }}>Remove Word Template</span>
            </Button>
          )}
        </div>
        <div>Upload a Word file (.docx) to use for document exports within this {PROJECT}.</div>
        <div className="my-20px">
          When exporting documents from Morta to Microsoft Word, the {PROJECT}&apos;s Default Word Document formatting
          and cover page will be applied.
        </div>
        <div className="my-20px">
          Please make sure your Word Template has the text{' '}
          <span className="text-bold">&#123;&#123;document.name&#125;&#125;</span> in the cover page. Morta will replace
          this text with the exported document&apos;s name. It is recommended to add a Table of Contents to your Word
          Template with the required styling. Moreover, ensure that the template includes the following styles:
        </div>
        <div className="mb-20px font-bold text-center">
          Heading 1, Heading 2, Heading 3, Heading 4, List Bullet, Hyperlink, Table Grid
        </div>
        <div
          className="m-15px"
          style={{
            padding: '20px',
            backgroundColor: constants.reallyLightYellow,
            border: `1px solid ${constants.lightYellow}`
          }}
        >
          <div className="flex flex-row items-center w-full text-lg font-bold mb-20px">
            <span style={{ color: constants.orange, fontSize: '120%', marginRight: '5px' }}>⚠</span> Important
          </div>

          <div className="mb-15px">
            If any required styles are missing when you upload a Word Document, Morta will flag them. To resolve this:
          </div>
          <div className="mb-15px" style={{ marginLeft: '15px' }}>
            <ol>
              <li>Add a piece of text using the missing style</li>
              <li>Delete the text and save the Word Document</li>
              <li>Re-upload the file to Morta</li>
            </ol>
          </div>
        </div>
      </div>

      <div className="text-sm">
        <div style={{ marginBottom: '40px' }}>
          <div style={{ marginTop: '40px' }}>
            {project.wordTemplate !== null && wordTemplateSrc && wordTemplateReady ? (
              <div>
                <a href={project.wordTemplate} target="_blank" rel="noreferrer">
                  Download Word Template
                </a>
              </div>
            ) : (
              <div>
                <a href="https://storage.googleapis.com/morta-new-live.appspot.com/public/Morta-Export-Template.docx">
                  Morta Export Template
                </a>
              </div>
            )}
          </div>

          <input
            type="file"
            id="file"
            ref={wordTemplateFile}
            onChange={onWordTemplateChange}
            style={{ display: 'none' }}
            accept=".docx"
          />
        </div>
      </div>

      <div className="mb-30px">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          <div>Document Styling</div>
          <Button className="ml-auto" internalType="accept" onClick={() => addHeadingLevel()}>
            + Add Heading Style
          </Button>

          {project.headingStyles.length > 0 && (
            <Button internalType="danger" style={{ marginLeft: '10px' }} onClick={() => removeTopStyle()}>
              Remove Top Heading Style
            </Button>
          )}
        </div>
        <div className="mb-20px">
          The document styles set the font, font size, color and style of section headings and default descriptions. You
          can also set the numbering style of your sections.
        </div>

        <div>
          <FormattingBox
            name="Document Name"
            formatting={{
              fontSize: project.processTitleFontSize,
              colour: project.processTitleColour,
              bold: project.processTitleBold,
              italic: project.processTitleItalic,
              underline: project.processTitleUnderline,
              alignment: project.processTitleAlignment
            }}
            onUpdate={(newFormatting: IHeadingStyle) => {
              updateProjectFormatting(newFormatting)
            }}
          />

          {project.headingStyles.map((style: IHeadingStyle, styleNumber: number) => {
            return (
              <FormattingBox
                key={styleNumber}
                name={`Heading Level ${styleNumber + 1}`}
                formatting={style}
                onUpdate={(newFormatting: IHeadingStyle) => updateStyle(newFormatting)}
              />
            )
          })}
        </div>
      </div>

      <div className="mb-30px">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          Document Default Banner Image
        </div>
        <div className="mb-15px">
          This is the banner image that will be displayed at the top of all documents on the Morta platform. You can
          override this banner image in individual documents by uploading a new banner image within the document.
        </div>

        <div>
          <div style={{ marginTop: '40px', marginBottom: '20px' }}>
            {project.defaultBanner !== null && bannerReady && bannerSrc ? (
              <img
                src={bannerSrc}
                alt={`${project.name} default banner image`}
                style={{ width: '100%', objectFit: 'cover' }}
              />
            ) : (
              <div>No {capitaliseFirstLetter(PROJECT)} default banner image has been set.</div>
            )}
          </div>
          {project.defaultBanner !== null && (
            <Button
              internalType="danger"
              onClick={() => onRemoveDefaultBanner()}
              style={{ marginTop: '30px', marginRight: '10px' }}
            >
              Remove Default Banner
            </Button>
          )}
          <Button internalType="primary" onClick={() => onBannerClick()} style={{ marginTop: '30px' }}>
            {project.defaultBanner === null ? 'Add Default Banner' : 'Change Default Banner'}
          </Button>
          <input
            type="file"
            id="file"
            ref={bannerFile}
            onChange={onDefaultBannerChange}
            style={{ display: 'none' }}
            accept="image/*"
          />
        </div>
      </div>

      <div className="mb-30px">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          Column Header and Text Colouring
        </div>

        <div className="mb-30px">
          This is the default colouring that will be applied to all new columns created within the{' '}
          {capitaliseFirstLetter(PROJECT)}.
        </div>

        <div className="mb-30px">
          <div className="font-bold mb-15px">Column Header Background Colour</div>
          <CompactPicker
            color={project.defaultHeaderBackgroundColor}
            onChange={(value: any) => {
              saveProjectInfo('defaultHeaderBackgroundColor', value.hex)
            }}
          />
          <div>
            <Button
              style={{ width: '245px' }}
              onClick={() => {
                saveProjectInfo('defaultHeaderBackgroundColor', '#f5f5f5')
              }}
            >
              Reset Colouring
            </Button>
          </div>
        </div>

        <div className="mb-30px">
          <div className="font-bold mb-15px">Column Header Text Colour</div>
          <CompactPicker
            color={project.defaultHeaderTextColor}
            onChange={(value: any) => {
              saveProjectInfo('defaultHeaderTextColor', value.hex)
            }}
          />
          <div>
            <Button
              style={{ width: '245px' }}
              onClick={() => {
                saveProjectInfo('defaultHeaderTextColor', '#333333')
              }}
            >
              Reset Colouring
            </Button>
          </div>
        </div>

        <Button internalType="danger" onClick={() => bulkApplyColumnColouring()}>
          Apply Colouring To All Columns
        </Button>
      </div>
    </div>
  )
}

export default Formatting

import React from 'react'
import { put } from 'helpers/http-service'
import {
  ITableViewWithColumns,
  ITableRow,
  ITableViewColumn,
  SelectKindOptions,
  EditorContent,
  IContext,
  IColumnAlterOptions,
  ITable,
  ICreateTableJoin,
  IUpdateTableViewCell
} from 'types'
import {
  ISpreadsheetData,
  SpreadsheetReducerActions,
  IColumnTypes,
  ITableViewHistoryCell
} from 'components/spreadsheet/types'
import {
  convertToApiFilter,
  getSpreadsheetRowsStream,
  getSortIndexForPosition,
  convertToApiColour
} from 'components/spreadsheet/helpers/functions'
import { SELECT_COLUMN_TYPES, ViewTypes, ViewTypesNames } from 'components/spreadsheet/constants/const'
import { history } from 'helpers/history'
import api from 'helpers/api'

export type ICreateTableColumnView = Omit<ITableViewColumn, 'publicId' | 'kindOptions'> & IContext
export const createTableColumnViewAction = (
  tableViewId: string,
  column: ICreateTableColumnView,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  onSuccess: () => void,
  onFailure: (error: any) => void
) => {
  setSpreadsheetData({ type: 'REFRESH', refreshing: true })
  api
    .createTableColumn(tableViewId, column)
    .then((response) => {
      setSpreadsheetData({ type: 'ADD_COLUMN', column: response.data as ITableViewColumn })
      onSuccess()
    })
    .catch((error) => {
      onFailure(error)
      setSpreadsheetData({ type: 'REFRESH', refreshing: false })
    })
}

export const deleteTableColumnAction = (
  tableId: string,
  columnId: string,
  projectId: string,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  onSuccess: () => void,
  onFailure: (error: any) => void
) => {
  setSpreadsheetData({ type: 'REFRESH', refreshing: true })
  api
    .deleteTableColumn(tableId, columnId, projectId)
    .then(() => {
      setSpreadsheetData({ type: 'DELETE_COLUMN', columnId })
      onSuccess()
    })
    .catch((error) => {
      onFailure(error)
      setSpreadsheetData({ type: 'REFRESH', refreshing: false })
    })
}

export const deleteTableRowAction = (
  rowIds: string[],
  projectId: string,
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  onSuccess: () => void,
  onFailure: () => void
) => {
  api
    .deleteTableRows(
      spreadsheetData.activeTableView,
      rowIds,
      projectId,
      spreadsheetData.processId,
      spreadsheetData.processSectionId,
      spreadsheetData.processResponseId
    )
    .then(() => {
      setSpreadsheetData({ type: 'DELETE_ROWS', rowIds })
      onSuccess()
    })
    .catch(() => {
      onFailure()
    })
}

export interface ITableViewHistory extends IContext {
  type: 'EDIT_CELL' | 'DELETE_ROWS' | 'ADD_ROW'
  cells?: ITableViewHistoryCell[]
}

export const updateTableViewCellsAction = (
  tableViewId: string,
  updateTableViewPayload: IUpdateTableViewCell,
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  onSuccess: () => void,
  onError: (error: any) => void,
  ignoreHistory?: boolean
) => {
  // Remap columnId to columnName for the API
  const newHistory: ITableViewHistory = { type: 'EDIT_CELL', cells: [], context: updateTableViewPayload.context }
  let errorCells = null
  const remappedCells = updateTableViewPayload.cells.map((cell) => {
    const previousValue = spreadsheetData.rows.find((row) => row.publicId === cell.rowId)?.rowData[cell.columnId]
    const column = spreadsheetData.viewDetails.columns.find((c) => c.publicId === cell.columnId)

    if (!column) {
      errorCells = cell.columnId

      return
    }
    const newCell = {
      ...cell,
      columnName: column.name
    }
    if (!ignoreHistory && previousValue !== undefined && newHistory.cells) {
      newHistory['cells'].push({
        rowId: cell.rowId,
        columnId: cell.columnId,
        oldValue: previousValue,
        newValue: cell.value
      })
    }
    return {
      columnName: newCell.columnName,
      rowId: newCell.rowId,
      value: newCell.value,
      processPublicId: newCell.processPublicId,
      processSectionPublicId: newCell.processSectionPublicId,
      processResponsePublicId: newCell.processResponsePublicId
    }
  })

  if (errorCells) {
    onError(`Column ${errorCells} not found in view`)
    return
  }

  // Add in process context
  const processContext = spreadsheetData.processId
    ? {
        processPublicId: spreadsheetData.processId,
        processSectionPublicId: spreadsheetData.processSectionId!,
        processResponsePublicId: spreadsheetData.processResponseId!
      }
    : {}

  const payload = {
    ...updateTableViewPayload,
    cells: remappedCells,
    context: { ...updateTableViewPayload.context, ...processContext }
  }

  api({ method: 'PUT', endpoint: `/table/views/${tableViewId}/cells`, data: payload })
    .then((response) => {
      const data = response.data as ITableRow[]

      const cellsToUpdate = []
      for (const row of data) {
        for (const column of spreadsheetData.viewDetails.columns) {
          if (column.formulaEnabled) {
            cellsToUpdate.push({
              rowId: row.publicId,
              columnId: column.publicId,
              value: {
                [column.publicId]: row.rowData[column.name]
              }
            })
          }
        }
      }

      setSpreadsheetData({
        type: 'BULK_EDIT_CELL',
        cells: cellsToUpdate
      })
      onSuccess()

      // Add history
      if (newHistory.cells && newHistory.cells.length > 0) {
        setSpreadsheetData({
          type: 'ADD_HISTORY',
          event: newHistory
        })
      }
    })
    .catch((error) => {
      onError(error)

      // Revert the edit cell action
      if (newHistory.cells && newHistory.cells.length > 0) {
        for (const history of newHistory.cells) {
          setSpreadsheetData({
            type: 'EDIT_CELL',
            rowId: history.rowId,
            columnId: history.columnId,
            value: { [history.columnId]: history.oldValue as string },
            oldValue: history.newValue as string
          })
        }
      }
    })
}

export const addNewView = (
  projectId: string,
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  type: number,
  onSuccess: () => void,
  onFailure: (error: any) => void
) => {
  const name = `New ${type === ViewTypes.SPREADSHEET ? 'Table' : ViewTypesNames[type]} View`
  api
    .createNewView(spreadsheetData.tableDetails.publicId, name, type, projectId)
    .then((response) => {
      const viewData = response.data as ITableViewWithColumns
      setSpreadsheetData({
        type: 'ADD_VIEW',
        view: { ...viewData, hiddenColumns: [] }
      })
      history.push(`/project/${projectId}/table/${spreadsheetData.tableDetails.publicId}/view/${viewData.publicId}`)
      onSuccess()
    })
    .catch((error) => {
      onFailure(error)
    })
}

export const saveCurrentView = (
  projectId: string,
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  onSuccess: () => void,
  onFailure: (error: any) => void
) => {
  const newTableView: Partial<ITableViewWithColumns> & IContext = {
    name: spreadsheetData.viewDetails.name,
    type: spreadsheetData.viewDetails.type,
    description: spreadsheetData.viewDetails.description,
    filterSettings: convertToApiFilter(spreadsheetData.userConfiguration.filterSettings),
    groupSettings: spreadsheetData.userConfiguration.groupSettings,
    sortSettings: spreadsheetData.userConfiguration.sortSettings,
    colourSettings: convertToApiColour(spreadsheetData.userConfiguration.colourSettings),
    chartSettings: spreadsheetData.userConfiguration.chartSettings,
    frozenIndex: spreadsheetData.viewDetails.frozenIndex,
    displayValidationErrorRows: spreadsheetData.viewDetails.displayValidationErrorRows,
    displayCommentRows: spreadsheetData.viewDetails.displayCommentRows,
    collapsedGroupView: spreadsheetData.viewDetails.collapsedGroupView,
    unpackMultiselectGroupView: spreadsheetData.viewDetails.unpackMultiselectGroupView,
    rowHeight: spreadsheetData.viewDetails.rowHeight,
    columns: spreadsheetData.viewDetails.columns
      .map((column) => {
        const newColumn = JSON.parse(JSON.stringify(column))
        if (newColumn['required'] === undefined) newColumn['required'] = false
        if (SELECT_COLUMN_TYPES.includes(newColumn.kind) && newColumn.kindOptions?.tableOptions)
          delete newColumn.kindOptions?.tableOptions['cachedOptions']

        return newColumn
      })
      .filter((column: ITableViewColumn) => !spreadsheetData.userConfiguration.hiddenColumns.includes(column.publicId)),
    context: { projectId }
  }

  api
    .updateWholeTableView(spreadsheetData.activeTableView, newTableView)
    .then(() => {
      setSpreadsheetData({ type: 'SAVE_CURRENT_VIEW' })
      onSuccess()
    })
    .catch((error) => {
      onFailure(error)
    })
}

export const updateTable = (
  field: 'name' | 'type' | 'logo' | 'keepValidationsInSync' | 'keepColoursInSync' | 'isDeleted' | 'syncHourlyFrequency',
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  value: string | string[] | boolean | number,
  projectId: string,
  onSuccess: (updatedTable: ITable) => void,
  onError: (message: string) => void
) => {
  let name = spreadsheetData.tableDetails.name
  let logo = spreadsheetData.tableDetails.logo
  let type = spreadsheetData.tableDetails.type
  let keepValidationsInSync = spreadsheetData.tableDetails.keepValidationsInSync
  let keepColoursInSync = spreadsheetData.tableDetails.keepColoursInSync
  let isDeleted = spreadsheetData.tableDetails.isDeleted
  let deletedAt = spreadsheetData.tableDetails.deletedAt
  let syncHourlyFrequency = spreadsheetData.tableDetails.syncHourlyFrequency

  if (field === 'name' && typeof value === 'string') name = value
  else if (field === 'logo' && typeof value === 'string') logo = value
  else if (field === 'type' && typeof value === 'string') type = value
  else if (field === 'keepValidationsInSync' && typeof value === 'boolean') keepValidationsInSync = value
  else if (field === 'keepColoursInSync' && typeof value === 'boolean') keepColoursInSync = value
  else if (field === 'isDeleted' && typeof value === 'boolean') {
    if (isDeleted === true && value === false) {
      deletedAt = null
      isDeleted = false
    } else if (isDeleted === false && value === true) {
      deletedAt = new Date().toISOString()
      isDeleted = true
    }
  } else if (field === 'syncHourlyFrequency' && typeof value === 'number') syncHourlyFrequency = value

  api
    .updateTable(spreadsheetData.tableDetails.publicId, {
      name,
      type,
      logo,
      keepValidationsInSync,
      keepColoursInSync,
      syncHourlyFrequency,
      context: { projectId: projectId }
    })
    .then((response) => {
      const tableUpdated = response.data
      if (field === 'isDeleted') {
        setSpreadsheetData({ type: 'UPDATE_TABLE', field: 'deletedAt', value: deletedAt })
        setSpreadsheetData({ type: 'UPDATE_TABLE', field: 'isDeleted', value: isDeleted })
      } else {
        setSpreadsheetData({ type: 'UPDATE_TABLE', field, value })
      }

      onSuccess(tableUpdated)
    })
    .catch((error) => {
      onError(error)
    })
}

export const createTableJoin = (
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  tableJoin: ICreateTableJoin,
  projectId: string,
  onSuccess: () => void,
  onError: (error: any) => void
) => {
  api
    .createTableJoin(spreadsheetData.tableDetails.publicId, projectId, tableJoin)
    .then((response) => {
      setSpreadsheetData({ type: 'UPDATE_TABLE_JOINS', table: response.data as ITable })
      onSuccess()
    })
    .catch((error) => {
      onError(error)
    })
}

export const updateTableJoin = (
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  tableJoinId: string,
  tableJoin: ICreateTableJoin,
  projectId: string,
  onSuccess: () => void,
  onError: (error: any) => void
) => {
  api
    .updateTableJoin(spreadsheetData.tableDetails.publicId, tableJoinId, projectId, tableJoin)
    .then((response) => {
      setSpreadsheetData({ type: 'UPDATE_TABLE_JOINS', table: response.data })
      onSuccess()
    })
    .catch((error) => {
      onError(error)
    })
}

export const deleteTableJoin = (
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  tableJoinId: string,
  projectId: string,
  onSuccess: () => void,
  onError: (error: any) => void
) => {
  api
    .deleteTableJoin(spreadsheetData.tableDetails.publicId, tableJoinId, projectId)
    .then((response) => {
      setSpreadsheetData({ type: 'UPDATE_TABLE_JOINS', table: response.data })
      onSuccess()
    })
    .catch((error) => {
      onError(error)
    })
}

export const deleteTable = (
  projectId: string,
  spreadsheetData: ISpreadsheetData,
  onSuccess: () => void,
  onFailure: (error: any) => void
) => {
  api
    .deleteTable(spreadsheetData.tableDetails.publicId, { context: { projectId } })
    .then(() => {
      onSuccess()
    })
    .catch((error) => {
      onFailure(error)
    })
}

export const updateView = (
  field:
    | 'name'
    | 'description'
    | 'type'
    | 'disableNewRow'
    | 'allowContributorDelete'
    | 'displayValidationErrorRows'
    | 'displayCommentRows'
    | 'collapsedGroupView'
    | 'unpackMultiselectGroupView',
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  viewId: string,
  value: string | EditorContent | null | number | boolean,
  projectId: string,
  onSuccess: () => void,
  onFailure: (error: string) => void
) => {
  api
    .updateTableView(viewId, projectId, field, value)
    .then(() => {
      setSpreadsheetData({ type: 'UPDATE_VIEW', field, viewId, value })
      onSuccess()
    })
    .catch((error) => {
      onFailure(error)
    })
}

export const deleteView = (
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  projectId: string,
  viewId: string,
  onSuccess: () => void,
  onFailure: (error: string) => void
) => {
  api
    .deleteView(viewId, projectId)
    .then(() => {
      setSpreadsheetData({ type: 'DELETE_VIEW', viewId })
      onSuccess()
    })
    .catch((error) => {
      onFailure(error)
    })
}

export const updateColumn = (
  projectId: string,
  field:
    | 'name'
    | 'description'
    | 'kind'
    | 'kindOptions'
    | 'script'
    | 'scriptEnabled'
    | 'formula'
    | 'formulaEnabled'
    | 'aggregate'
    | 'stringValidation'
    | 'hardValidation'
    | 'validationMessage'
    | 'validationNoBlanks'
    | 'validationNoDuplicates'
    | 'width'
    | 'thousandSeparator'
    | 'decimalPlaces'
    | 'dateFormat'
    | 'headerBackgroundColor'
    | 'headerTextColor'
    | 'exportWidth'
    | 'displayLink',
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  columnId: string,
  value: string | number | EditorContent | null | IColumnTypes | SelectKindOptions | boolean,
  onSuccess: () => void,
  onError: (error: any) => void,
  alterOptions?: IColumnAlterOptions
) => {
  setSpreadsheetData({ type: 'REFRESH', refreshing: true })
  let column: Partial<ITableViewColumn> & IContext & Partial<{ alterOptions: IColumnAlterOptions }> = {
    publicId: columnId,
    [field]: value,
    context: { projectId: projectId }
  }

  if (field === 'kind' && (value === 'select' || value === 'multiselect')) {
    const foundColumn = spreadsheetData.viewDetails.columns.find((column) => column.publicId === columnId)
    const prevKind = foundColumn?.kind
    if (foundColumn && (prevKind === 'select' || prevKind === 'multiselect')) {
      if (foundColumn.kindOptions?.tableOptions.cachedOptions) {
        delete foundColumn.kindOptions?.tableOptions['cachedOptions']
      }
      column = { ...column, kindOptions: foundColumn.kindOptions }
    }
  }
  if (field === 'kindOptions') {
    const foundColumn = spreadsheetData.viewDetails.columns.find((column) => column.publicId === columnId)
    column = { ...column, kind: foundColumn?.kind }
  }
  if (alterOptions) {
    column = {
      ...column,
      alterOptions
    }
  }

  api
    .updateTableColumn(spreadsheetData.activeTableView, columnId, column)
    .then(async (response) => {
      setSpreadsheetData({ type: 'UPDATE_COLUMN', column: response.data })
      onSuccess()
      if (
        field === 'kind' ||
        field === 'script' ||
        field === 'scriptEnabled' ||
        field === 'formula' ||
        field === 'formulaEnabled'
      ) {
        let firstBatch = true
        for await (const rowsBatched of getSpreadsheetRowsStream(
          spreadsheetData.isAdmin,
          spreadsheetData.tableDetails.publicId,
          spreadsheetData.activeTableView,
          spreadsheetData.viewDetails.columns,
          spreadsheetData.processId
        )) {
          // On the first batch, we set the spreadsheet data, so that everything starts to render
          // and we take the spreadsheet out of the loading state
          if (firstBatch) {
            firstBatch = false
            const firstBatchInitialSize = 100

            // We break up the first batch into a set of 100 records, and the rest, when the initial
            // set is sent using SET_SPREADSHEET_DATA, the component will render the rows, we then
            // send the rest of the first batch
            const firstSet = rowsBatched.batchRows.slice(0, firstBatchInitialSize)

            setSpreadsheetData({ type: 'REFRESH_SPREADSHEET_ROWS', rows: firstSet, totalRows: firstSet.length })
            // The second half of the first batch
            if (rowsBatched.batchRows.length > firstBatchInitialSize) {
              const secondSet = rowsBatched.batchRows.slice(firstBatchInitialSize)
              setSpreadsheetData({ type: 'APPEND_SPREADSHEET_ROWS', rows: secondSet })
            }

            // for subsequent batches we just append the incoming rows
          } else {
            setSpreadsheetData({
              type: 'APPEND_SPREADSHEET_ROWS',
              rows: rowsBatched.batchRows
            })
          }
        }

        setSpreadsheetData({ type: 'STREAMING', streaming: false })
      } else {
        onSuccess()
      }
    })
    .catch((error) => {
      setSpreadsheetData({ type: 'REFRESH', refreshing: false })
      onError(error)
    })
}

export const updateRowPosition = (
  projectId: string,
  rowId: string,
  newIndex: number,
  spreadsheetData: ISpreadsheetData,
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  onSuccess: () => void,
  onFailure: () => void
) => {
  const row = spreadsheetData.rows.find((row: ITableRow) => row.publicId === rowId)

  if (row && !spreadsheetData.streaming) {
    const rows = spreadsheetData.rows.filter((r) => r.publicId !== row.publicId)
    if (rows.length === 0) {
      return
    }

    const newSortOrder = getSortIndexForPosition(rows, newIndex)
    put(`/table/views/${spreadsheetData.viewDetails.publicId}/rows`, {
      rows: [
        {
          publicId: rowId,
          sortOrder: newSortOrder,
          // XXX: do we need to send this?
          rowData: Object.fromEntries(
            Object.entries(row.rowData).filter(([k]) => spreadsheetData.originalViewColumns.includes(k))
          )
        }
      ],
      context: {
        projectId: projectId,
        processId: spreadsheetData.processId,
        processSectionId: spreadsheetData.processSectionId,
        processResponseId: spreadsheetData.processResponseId
      }
    })
      .then(() => {
        setSpreadsheetData({ type: 'CHANGE_ROW_ORDER', rowId, newIndex, newSortOrder })
        onSuccess()
      })
      .catch(() => {
        onFailure()
      })
  } else {
    onFailure()
  }
}

export const searchTable = (setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>, searchTerm: string) => {
  setSpreadsheetData({ type: 'SEARCH_TABLE', searchTerm })
}

export const refreshSelectOptions = async (
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>,
  spreadsheetData: ISpreadsheetData
) => {
  const hasDependency = spreadsheetData.viewDetails.columns.find(
    (column) =>
      column.kind === 'select' &&
      column.kindOptions?.tableOptions.dependencies &&
      column.kindOptions?.tableOptions.dependencies.length > 0
  )
  if (hasDependency) {
    const response = await api.getTableView(spreadsheetData.activeTableView)
    setSpreadsheetData({ type: 'REFRESH_SELECT_OPTIONS', view: response.data })
  }
}

import React, { useEffect, useState, createContext, useContext, useReducer, useCallback, useMemo } from 'react'
import {
  ISpreadsheetData,
  ITableRowInGroup,
  IViewPortColumn,
  IGroupRowCreate,
  IGroupCollapsed,
  GroupByDictionary,
  IColumnValuesCount,
  ISelectedCellState,
  ISelectedCellRange,
  ITableViewHistoryCell
} from 'components/spreadsheet/types'
import {
  KEY_PRESS,
  KEY_PRESSES,
  getInitialSpreadsheetState,
  INITIAL_SELECTED_CELL_RANGE,
  INITIAL_SELECTED_CELL,
  GROUP_KEY_SPLIT,
  ViewTypes
} from 'components/spreadsheet/constants/const'
import {
  getSpreadsheetData,
  getSpreadsheetRowsStream,
  createCellId,
  getRowHeightVariable,
  sortRows,
  getSortIndexForPosition,
  convertRowDataToNameValueMap,
  convertRowDataToIdValueMap,
  getMissingColumns
} from 'components/spreadsheet/helpers/functions'
import { SpreadsheetReducerActions, ISelectedCell } from 'components/spreadsheet/types'
import constants from 'style/constants.module.scss'
import { ITableColumn, ITableRow, ITableViewColumn, ICellValue, IProcessObject, IContextMenuState } from 'types'
import { spreadsheetDataReducer } from 'components/spreadsheet/contexts/data/reducer'
import { PERMISSIONS } from 'helpers/auth'
import { updateTableViewCellsAction } from 'components/spreadsheet/contexts/data/actions'
import { useRowsViewport } from 'components/spreadsheet/hooks/useRowsViewport'
import { useColumnsViewport } from 'components/spreadsheet/hooks/useColumnsViewport'
import useGridLayout from 'components/spreadsheet/hooks/useGridLayout'
import { useAuth } from 'hooks/auth'
import { useProject } from 'hooks/project'
import { useApplicationStore } from 'hooks/application'
import { filterRows } from 'components/spreadsheet/helpers/filtering'
import {
  checkValuesSame,
  reformattedPasteData,
  transformStringToCorrectFormat,
  validatePasteField
} from 'components/spreadsheet/helpers/paste'
import { isReadOnly, isValidValue } from 'components/spreadsheet/helpers/validation'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import useSocket from 'hooks/socket'
import { ITableViewColour, ITableViewCell, IUpdateTableViewCell } from 'types'
import api from 'helpers/api'

export interface DataContextProps {
  spreadsheetData: ISpreadsheetData
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>
  selectedCell: ISelectedCellState
  setSelectedCell: (selectedCell: ISelectedCellState) => void
  selectedCellRange: ISelectedCellRange
  setSelectedCellRange: (selectedCellRange: ISelectedCellRange) => void
  maxHeight: number
  verticalScroll: (top: number) => void
  horizontalScroll: (left: number) => void
  setScrollTop: (scroll: number) => void
  setScrollLeft: (scroll: number) => void
  gridRef: React.RefObject<HTMLDivElement>
  handleKeyDown: (event: React.KeyboardEvent, newSelectedCell: ISelectedCell) => void
  handleKeyDownForm: (event: React.KeyboardEvent, newSelectedCell: ISelectedCell) => void
  uniqueNumber: number
  setCellValue: (cells: IUpdateTableViewCell, onSuccess: () => void, onError: (error?: string) => void) => void
  rows: Array<ITableRow | ITableRowInGroup | IGroupRowCreate | IGroupCollapsed>
  groupedRows: GroupByDictionary | undefined
  startRowIndex: number
  endRowIndex: number
  columns: IViewPortColumn[]
  visibleColumns: string[]
  handleToggleGroup: (groupKey: string) => void
  handleToggleCollapseAll: () => void
  handleCreateRow: (values?: Record<string, ICellValue>, position?: number) => Promise<void>
  handleCreateBlankRows: (noRows: number, values?: Record<string, ICellValue>) => Promise<void>
  setIsFocused: (isFocused: boolean) => void
  filterMenu: IContextMenuState
  setFilterMenu: (filterMenu: IContextMenuState) => void
  colourMenu: IContextMenuState
  setColourMenu: (colourMenu: IContextMenuState) => void
}

export const DataContext = createContext<DataContextProps | undefined>(undefined)

interface DataContextProviderProps {
  tableId: string
  tableViewId: string
  processId?: string
  processSectionId?: string
  processResponseId?: string
  permissionCap?: number
  children?: React.ReactNode
  process?: IProcessObject
}

export const DataContextProvider = (props: DataContextProviderProps) => {
  const { project, tags } = useProject()
  const { socket } = useSocket()
  const { user } = useAuth()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()

  const [spreadsheetData, setSpreadsheetData] = useReducer(spreadsheetDataReducer, getInitialSpreadsheetState())
  const [selectedCell, updateSelectedCell] = useState<ISelectedCellState>(INITIAL_SELECTED_CELL)
  const [selectedCellRange, updateSelectedCellRange] = useState<ISelectedCellRange>(INITIAL_SELECTED_CELL_RANGE)
  const [isFocused, updateIsFocused] = useState<boolean>(false)

  // Menus
  const [filterMenu, setFilterMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [colourMenu, setColourMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)

  // eslint-disable-next-line
  const [uniqueNumber, _] = useState<number>(Math.random())
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollTop, setScrollTop] = useState(0)

  const rowHeight = getRowHeightVariable(spreadsheetData.viewDetails.rowHeight)
  const { gridRef, gridHeight } = useGridLayout()

  const { rows, maxHeight, startRowIndex, endRowIndex, groupedRows, expandedRowsWithGroups } = useRowsViewport({
    gridHeight,
    scrollTop,
    rawRows: spreadsheetData.rows,
    groupBy: spreadsheetData.userConfiguration.groupSettings,
    totalRows: spreadsheetData.totalRows,
    rowHeight,
    collapsedGroupIds: spreadsheetData.collapsedGroupIds,
    isStreaming: spreadsheetData.streaming,
    columns: spreadsheetData.viewDetails.columns,
    isContributer: spreadsheetData.isContributor,
    disableNewRow: spreadsheetData.viewDetails.disableNewRow || spreadsheetData.tableDetails.isSynced,
    unpackMultiselectGroupView: spreadsheetData.viewDetails.unpackMultiselectGroupView
  })

  const { columns, lastFrozenColumnIndex } = useColumnsViewport({
    rawColumns: spreadsheetData.viewDetails.columns,
    frozenIndex: spreadsheetData.viewDetails.frozenIndex,
    rawGroupByColumns: spreadsheetData.userConfiguration.groupSettings,
    hiddenColumnIds: spreadsheetData.userConfiguration.hiddenColumns
  })

  const checkSelectedCellExists = (cellId: string) => {
    const selectedCellElement = document.getElementById(cellId)
    if (selectedCellElement) return selectedCellElement
    else return null
  }

  const verticalScroll = (top: number) => {
    if (!gridRef.current) {
      return
    }
    gridRef.current.scrollTop = top
  }

  const horizontalScroll = (left: number) => {
    if (!gridRef.current) {
      return
    }
    gridRef.current.scrollLeft = left
  }

  const setSelectedCell = useCallback((selectedCell: ISelectedCellState) => {
    updateSelectedCell(selectedCell)
    if (selectedCell.rowId === '' && selectedCell.columnId === '') {
      setIsFocused(false)
      setSelectedCellRange(INITIAL_SELECTED_CELL_RANGE)
    }
  }, [])

  const setIsFocused = useCallback((isFocused: boolean) => {
    updateIsFocused(isFocused)
  }, [])

  const setSelectedCellRange = useCallback(
    (selectedCellRange: ISelectedCellRange) => {
      if (isFocused) {
        updateSelectedCellRange(selectedCellRange)
      } else {
        if (selectedCellRange.endColumnIndex === -1) {
          updateSelectedCellRange(selectedCellRange)
        }
      }
    },
    [isFocused]
  )

  const loadInitialData = async () => {
    const newSpreadsheetData = await getSpreadsheetData(
      props.tableId,
      props.tableViewId,
      props.processId,
      props.process
    )

    // If the spreadsheet is not being loaded in a process set the title
    if (!props.processId && !props.processSectionId && !props.processResponseId)
      document.title = `${newSpreadsheetData.tableDetails.name}`

    // If the spreadsheet is being loaded within a process then table admin tools should be disallowed
    if (
      props.processId ||
      props.processSectionId ||
      props.processResponseId ||
      (props.permissionCap && props.permissionCap < PERMISSIONS.owner)
    ) {
      newSpreadsheetData['processId'] = props.processId
      newSpreadsheetData['processSectionId'] = props.processSectionId
      newSpreadsheetData['processResponseId'] = props.processResponseId
      newSpreadsheetData['isAdmin'] = false
      const view_column_ids = newSpreadsheetData['viewDetails']['columns'].map(
        (column: ITableViewColumn) => column.publicId
      )
      newSpreadsheetData['userConfiguration']['colourSettings'] = newSpreadsheetData['viewDetails'][
        'colourSettings'
      ].filter((colourSetting: ITableViewColour) => view_column_ids.includes(colourSetting.columnId))
    } else {
      newSpreadsheetData['userConfiguration']['colourSettings'] = newSpreadsheetData['viewDetails']['colourSettings']
    }

    // If the spreadsheet has been deleted cap at read access
    if (newSpreadsheetData['tableDetails']['isDeleted']) {
      newSpreadsheetData['isContributor'] = false
      newSpreadsheetData['isAdmin'] = false
    }

    // If the spreadsheet is being loaded in a process and a permission cap is in place (as a response has been received for example) then set permissions in the s to that level
    if (props.permissionCap !== undefined) {
      newSpreadsheetData['isContributor'] =
        newSpreadsheetData['isContributor'] && props.permissionCap >= PERMISSIONS.contributor
    }

    // If the user is an admin copy over the view details to the user configuration
    if (newSpreadsheetData['isAdmin']) {
      newSpreadsheetData['userConfiguration']['filterSettings'] = newSpreadsheetData['viewDetails']['filterSettings']
      newSpreadsheetData['userConfiguration']['sortSettings'] = newSpreadsheetData['viewDetails']['sortSettings']
      newSpreadsheetData['userConfiguration']['chartSettings'] = newSpreadsheetData['viewDetails']['chartSettings']

      const viewColumns = newSpreadsheetData['viewDetails']['columns'].map(
        (column: ITableViewColumn) => column.publicId
      )
      newSpreadsheetData['userConfiguration']['hiddenColumns'] = newSpreadsheetData['tableDetails']['columns']
        .filter((column: ITableColumn) => !viewColumns.includes(column.publicId))
        .map((column: ITableColumn) => column.publicId)

      const missingColumns = getMissingColumns(
        newSpreadsheetData['userConfiguration']['hiddenColumns'],
        newSpreadsheetData
      )
      newSpreadsheetData['viewDetails']['columns'] = newSpreadsheetData['viewDetails']['columns'].concat(missingColumns)
      newSpreadsheetData['viewDetails']['hiddenColumns'] = missingColumns.map((col) => {
        return { columnId: col.publicId, columnName: col.name }
      })
    }
    newSpreadsheetData['userConfiguration']['groupSettings'] = newSpreadsheetData['viewDetails']['groupSettings']

    let firstBatch = true
    let columnValuesCount: IColumnValuesCount = {}

    for (let x = 0; x < newSpreadsheetData['viewDetails']['columns'].length; x++) {
      const column = newSpreadsheetData['viewDetails']['columns'][x]
      columnValuesCount[column.publicId] = {}
    }

    for await (const rowsBatched of getSpreadsheetRowsStream(
      newSpreadsheetData['isAdmin'],
      props.tableId,
      props.tableViewId,
      newSpreadsheetData['isAdmin'] ? newSpreadsheetData.tableDetails.columns : newSpreadsheetData.viewDetails.columns,
      newSpreadsheetData.processId
    )) {
      columnValuesCount = rowsBatched.seenValues
      // On the first batch, we set the spreadsheet data, so that everything starts to render
      // and we take the spreadsheet out of the loading state
      if (firstBatch) {
        firstBatch = false
        const firstBatchInitialSize = 100

        // We break up the first batch into a set of 100 records, and the rest, when the initial
        // set is sent using SET_SPREADSHEET_DATA, the component will render the rows, we then
        // send the rest of the first batch
        const firstSet = rowsBatched.batchRows.slice(0, firstBatchInitialSize)

        newSpreadsheetData.rows = firstSet
        newSpreadsheetData.totalRows = firstSet.length
        newSpreadsheetData.streaming = true

        setSpreadsheetData({ type: 'SET_SPREADSHEET_DATA', spreadsheetData: newSpreadsheetData })

        // The second half of the first batch
        if (rowsBatched.batchRows.length > firstBatchInitialSize) {
          const secondSet = rowsBatched.batchRows.slice(firstBatchInitialSize)
          setSpreadsheetData({ type: 'APPEND_SPREADSHEET_ROWS', rows: secondSet })
        }

        // for subsequent batches we just append the incoming rows
      } else {
        setSpreadsheetData({
          type: 'APPEND_SPREADSHEET_ROWS',
          rows: rowsBatched.batchRows
        })
      }
    }

    // This indicates that there were no rows sent from the server, so the above for loop was
    // not run
    if (firstBatch) {
      setSpreadsheetData({ type: 'SET_SPREADSHEET_DATA', spreadsheetData: newSpreadsheetData })
    }

    setSpreadsheetData({ type: 'STREAMING', streaming: false })
    setSpreadsheetData({ type: 'SET_COLUMN_VALUES_COUNT', columnValuesCount })
  }

  // This loads the intial table, view and row data
  useEffect(() => {
    setSpreadsheetData({ type: 'LOADING', loading: true })
    if (props.tableId) loadInitialData()
  }, [props.tableId])

  // Handle copy/paste/cut
  const handleEvent = (event: ClipboardEvent | KeyboardEvent) => {
    return event.composedPath().find((item: EventTarget) => {
      const element = item as HTMLElement
      const inProcess = spreadsheetData.processId || spreadsheetData.processSectionId

      return (
        element instanceof HTMLInputElement ||
        element instanceof HTMLTextAreaElement ||
        element.id === 'table-audit-modal' ||
        element.id === 'row-comments' ||
        (element.className &&
          (element.className.includes('cell-value-editing') ||
            (element.className.includes('DraftEditor-editorContainer') && !inProcess) ||
            element.className.includes('ReactCodeMirror')))
      )
    })
  }

  const getSelectedRange = () => {
    if (selectedCell !== INITIAL_SELECTED_CELL && selectedCellRange === INITIAL_SELECTED_CELL_RANGE) {
      const cellRange = { ...INITIAL_SELECTED_CELL_RANGE }
      cellRange['endRowIndex'] = selectedCell.rowNumber
      cellRange['endColumnIndex'] = selectedCell.columnNumber
      return cellRange
    } else {
      return selectedCellRange
    }
  }

  useEffect(() => {
    if (socket.connected && spreadsheetData.tableDetails.publicId !== '') {
      socket.emit('join', spreadsheetData.tableDetails.publicId)

      socket.on('multiple_cells_updated', (data) => {
        setSpreadsheetData({
          type: 'BULK_EDIT_CELL',
          cells: data.values.map((item) => {
            return { rowId: item.rowId, columnId: data.columnId, value: { [data.columnId]: item.value } }
          })
        })
      })

      // TODO: handle changes between users using websocket
      // socket.on('event', (data) => {
      //   if ('change' in data) {
      //     if ('values_changed' in data.change) {
      //       const changes = Object.keys(data.change.values_changed)
      //       for (let i = 0; i < changes.length; i++) {
      //         const change = changes[i]
      //         const changeObject = data.change.values_changed[change]
      //         const changeReformatted = change.replace("root['", '').replace("']['value']", '')
      //         const [columnName, rowId] = changeReformatted.split('/')
      //         const columnId = spreadsheetData.viewDetails.columns.find((col) => col.name === columnName)?.publicId
      //         if (columnId) {
      //           setSpreadsheetData({
      //             type: 'EDIT_CELL',
      //             columnId,
      //             rowId,
      //             oldValue: changeObject.old_value,
      //             value: { [columnId]: changeObject.new_value }
      //           })
      //         }
      //       }
      //     } else if ('iterable_item_removed' in data.change) {
      //       const changes = Object.keys(data.change.iterable_item_removed)
      //       for (let i = 0; i < changes.length; i++) {
      //         const change = changes[i]
      //         const changeObject = data.change.iterable_item_removed[change]
      //         const changeReformatted = change.replace("root['", '').replace("']['value'][0]", '')
      //         const [columnName, rowId] = changeReformatted.split('/')
      //         const columnId = spreadsheetData.viewDetails.columns.find((col) => col.name === columnName)?.publicId
      //         if (columnId) {
      //           setSpreadsheetData({
      //             type: 'EDIT_CELL',
      //             columnId,
      //             rowId,
      //             oldValue: changeObject,
      //             value: { [columnId]: null }
      //           })
      //         }
      //       }
      //     } else if ('iterable_item_added' in data.change) {
      //       const changes = Object.keys(data.change.iterable_item_added)
      //       for (let i = 0; i < changes.length; i++) {
      //         const change = changes[i]
      //         const changeObject = data.change.iterable_item_added[change]
      //         const changeReformatted = change.replace("root['", '').replace("']['value'][0]", '')
      //         const [columnName, rowId] = changeReformatted.split('/')
      //         const columnId = spreadsheetData.viewDetails.columns.find((col) => col.name === columnName)?.publicId
      //         if (columnId) {
      //           setSpreadsheetData({
      //             type: 'EDIT_CELL',
      //             columnId,
      //             rowId,
      //             oldValue: null,
      //             value: { [columnId]: changeObject }
      //           })
      //         }
      //       }
      //     }
      //   }
      // })
    }

    return () => {
      if (socket.connected && spreadsheetData.tableDetails.publicId !== '') {
        socket.off('multiple_cells_updated')
        socket.emit('leave', spreadsheetData.tableDetails.publicId)
      }
    }
  }, [spreadsheetData.tableDetails.publicId])

  const checkLineBreaks = (text: string) => {
    const match = text.match(/(\r\n|\n|\r)/gm)
    return match
  }

  const createCopyFromCells = () => {
    let text = ''
    const isGroupingEnabled = spreadsheetData.userConfiguration.groupSettings.length > 0
    const rows = isGroupingEnabled ? expandedRowsWithGroups : spreadsheetData.rows
    const cellRange = getSelectedRange()
    const isForm = spreadsheetData.viewDetails.type === ViewTypes.FORM

    if (isForm) {
      const value = selectedCell.value
      text = reformattedCopyData(value)
      return text
    }

    const startRowIndex = Math.min(selectedCell.rowNumber, cellRange.endRowIndex)
    const endRowIndex = isForm ? 0 : Math.max(selectedCell.rowNumber, cellRange.endRowIndex)
    const startColumnIndex = Math.min(selectedCell.columnNumber, cellRange.endColumnIndex)
    const endColumnIndex = Math.max(selectedCell.columnNumber, cellRange.endColumnIndex)

    const nonGroupedColumns = columns
      .filter((vColumn) => visibleColumns.includes(vColumn.column.publicId))
      .filter((column) => !column.isGrouped)
    if (startRowIndex >= 0 && endRowIndex >= 0 && startColumnIndex >= 0 && endColumnIndex >= 0) {
      for (let i = startRowIndex; i < endRowIndex + 1; i++) {
        const row = rows[i]
        for (let j = startColumnIndex; j < endColumnIndex + 1; j++) {
          const column = nonGroupedColumns[j]
          if (row && column) {
            const value = 'rowData' in row ? row['rowData'][column.column.publicId] : null
            text += reformattedCopyData(value)
            if (j + 1 !== endColumnIndex + 1) text += '\t'
          }
        }
        if (i + 1 !== endRowIndex + 1) text += '\n'
      }
    }
    return text
  }

  const reformattedCopyData = (value: any) => {
    let text = ''
    const reformattedValue =
      value === null || value === undefined ? ' ' : typeof value === 'string' ? value : JSON.stringify(value)
    if (checkLineBreaks(reformattedValue)) text += '"' + reformattedValue + '"'
    else text += reformattedValue
    return text
  }

  const handleCopy = (event: ClipboardEvent) => {
    if (isFocused && !handleEvent(event)) {
      try {
        const text = createCopyFromCells()
        navigator.clipboard.writeText(text)
        setSnackbarMessage({
          status: 'success',
          message: `Copied to clipboard`
        })
      } catch (error) {
        displayErrorMessage(error)
      }
    }
  }

  const handlePaste = (event: ClipboardEvent) => {
    if (isFocused && !handleEvent(event)) {
      if (!navigator.clipboard.readText) {
        setSnackbarMessage({
          status: 'error',
          message: 'Paste event is not supported by your browser.'
        })
        return
      }
      navigator.clipboard
        .readText()
        .then((data) => {
          const isGroupingEnabled = spreadsheetData.userConfiguration.groupSettings.length > 0
          const spreadsheetRows = isGroupingEnabled ? expandedRowsWithGroups : spreadsheetData.rows
          const cells: ITableViewCell[] = []
          const reformattedData = reformattedPasteData(data)
          const rows = reformattedData
          const startRowIndex = selectedCell.rowNumber
          const startColumnIndex = selectedCell.columnNumber
          const endRowIndex =
            rows.length === 1
              ? selectedCellRange.endRowIndex !== -1
                ? selectedCellRange.endRowIndex
                : selectedCell.rowNumber
              : rows.length + startRowIndex - 1
          let endColumnIndex = selectedCell.columnNumber
          const resetStartRowIndex = Math.min(startRowIndex, endRowIndex)
          const resetEndRowIndex = Math.max(startRowIndex, endRowIndex)
          const nonGroupedColumns = columns
            .filter((vColumn) => visibleColumns.includes(vColumn.column.publicId))
            .filter((column) => !column.isGrouped)

          // Reformat the paste data into useful cells
          for (let i = 0; i <= resetEndRowIndex - resetStartRowIndex; i++) {
            const pasteRow = rows[i] ? rows[i] : rows[0]
            const row = spreadsheetRows[resetStartRowIndex + i]
            if (row) {
              const rowId = 'publicId' in row ? row.publicId : null
              if (rowId) {
                const pasteColumns = pasteRow.split('\t')
                endColumnIndex =
                  pasteColumns.length === 1
                    ? selectedCellRange.endColumnIndex !== -1
                      ? selectedCellRange.endColumnIndex
                      : selectedCell.columnNumber
                    : pasteColumns.length + startColumnIndex - 1

                const resetStartColumnIndex = Math.min(startColumnIndex, endColumnIndex)
                const resetEndColumnIndex = Math.max(startColumnIndex, endColumnIndex)

                for (let j = 0; j <= resetEndColumnIndex - resetStartColumnIndex; j++) {
                  let pasteColumnValue: string | null = pasteColumns[j] ? pasteColumns[j] : ''
                  pasteColumnValue = pasteColumnValue.trim()

                  if (
                    pasteColumnValue.slice(0, 1) === '"' &&
                    pasteColumnValue.slice(pasteColumnValue.length - 1) === '"'
                  )
                    pasteColumnValue = pasteColumnValue.slice(1, pasteColumnValue.length - 1)

                  pasteColumnValue = pasteColumnValue === '' ? null : pasteColumnValue
                  const column = nonGroupedColumns[resetStartColumnIndex + j]

                  if (column) {
                    const columnId = column.column.publicId
                    cells.push({
                      rowId,
                      columnId,
                      value: pasteColumnValue
                    })
                  }
                }
              }
            }
          }

          const cellsToSend = []
          const newCells = []
          const tempRowDict: Record<string, any> = {}
          for (let i = 0; i < cells.length; i++) {
            const cell = cells[i]
            const _spreadsheetRows = spreadsheetRows as
              | Array<ITableRow | ITableRowInGroup | IGroupRowCreate | IGroupCollapsed>
              | ITableRow[]
              | (ITableRowInGroup | IGroupRowCreate | IGroupCollapsed)[]
            const row = _spreadsheetRows.find(
              (row: ITableRow | (ITableRowInGroup | IGroupRowCreate | IGroupCollapsed)) =>
                'publicId' in row && row.publicId === cell.rowId
            )
            const column = columns.find((column) => column.column.publicId === cell.columnId)

            if (column && row && 'rowData' in row) {
              const oldValue = row['rowData'][column.column.publicId]

              // Keep an updated version of row data with values from the paste
              // so other cells can be validated against the new values
              tempRowDict[cell.rowId] = {
                ...(cell.rowId in tempRowDict ? tempRowDict[cell.rowId] : row['rowData']),
                [cell.columnId]: cell.value
              }

              try {
                if (isReadOnly(column, spreadsheetData.isContributor)) throw 'Cannot paste into a read only column.'
                cell.value = transformStringToCorrectFormat(cell.value as string | null, column.column.kind)
                const validValue = isValidValue(
                  cell.value,
                  tempRowDict[cell.rowId],
                  spreadsheetData.columnValuesCount,
                  column.column,
                  undefined,
                  spreadsheetData.viewDetails.type
                )
                const validatedValue = validatePasteField(cell.value, column.column)

                if (validValue.error || !validatedValue) {
                  throw `'${cell.value}' is not a valid value for column '${column.column.name}'.`
                }

                const sameValue = checkValuesSame(cell.value, oldValue, column.column.kind)
                if (!sameValue) cellsToSend.push(i)
              } catch (error) {
                const message: string = error ? (error as string) : ''
                setSnackbarMessage({ status: 'error', message })
              }
            }
          }

          for (let i = 0; i < cellsToSend.length; i++) {
            newCells.push(cells[cellsToSend[i]])
          }

          // Update the cells locally
          setSpreadsheetData({
            type: 'BULK_EDIT_CELL',
            cells: newCells.map((cell) => {
              return { rowId: cell.rowId, columnId: cell.columnId, value: { [cell.columnId]: cell.value } }
            })
          })

          // Send cells to the backend
          if (newCells.length > 0) {
            const cellUpdates = {
              cells: newCells,
              context: { projectId: project.publicId }
            }

            updateTableViewCellsAction(
              spreadsheetData.viewDetails.publicId,
              cellUpdates,
              spreadsheetData,
              setSpreadsheetData,
              () => {
                return null
              },
              (error) => {
                displayErrorMessage(error)
                return null
              }
            )
          }

          // Set the range
          setSelectedCellRange({ endRowIndex, endColumnIndex })
        })
        .catch(() => {
          setSnackbarMessage({
            status: 'error',
            message: 'Please check that you have enabled copy and paste in your browser.'
          })
        })
    }
  }

  const handleKeyDownListener = (event: KeyboardEvent) => {
    if (isFocused && !handleEvent(event)) {
      const isGroupingEnabled = spreadsheetData.userConfiguration.groupSettings.length > 0
      const rows = isGroupingEnabled ? expandedRowsWithGroups : spreadsheetData.rows
      const isUndoEvent = (event.metaKey || event.ctrlKey) && !event.shiftKey && event.key.toLowerCase() === 'z'
      const isRedoEvent =
        (event.metaKey || event.ctrlKey) &&
        ((event.shiftKey && event.key.toLowerCase() === 'z') || event.key.toLowerCase() === 'y')

      if (event.shiftKey && event.key === 'Enter') {
        if (!isGroupingEnabled) {
          const rowNumber = rows.findIndex(
            (row: ITableRow | ITableRowInGroup | IGroupRowCreate | IGroupCollapsed) =>
              'publicId' in row && row.publicId === selectedCell.rowId
          )

          if (rowNumber === rows.length - 1) {
            setSelectedCell(INITIAL_SELECTED_CELL)
            setSelectedCellRange(INITIAL_SELECTED_CELL_RANGE)
            const addRowElement = document.getElementById(`add-row-${uniqueNumber}`)
            if (addRowElement) {
              addRowElement.click()
            }
          }
        } else {
          const addRowElement = document.getElementById(`add-group-row-${uniqueNumber}-${selectedCell.rowId}`)

          if (addRowElement) {
            setSelectedCell(INITIAL_SELECTED_CELL)
            setSelectedCellRange(INITIAL_SELECTED_CELL_RANGE)
            addRowElement.click()
          }
        }
      }

      // Delete key event
      if (event.key === 'Delete') {
        const cellRange = getSelectedRange()

        const startRowIndex = Math.min(selectedCell.rowNumber, cellRange.endRowIndex)
        const endRowIndex = Math.max(selectedCell.rowNumber, cellRange.endRowIndex)
        const startColumnIndex = Math.min(selectedCell.columnNumber, cellRange.endColumnIndex)
        const endColumnIndex = Math.max(selectedCell.columnNumber, cellRange.endColumnIndex)

        const newCells: ITableViewCell[] = []

        const nonGroupedColumns = columns
          .filter((vColumn) => visibleColumns.includes(vColumn.column.publicId))
          .filter((column) => !column.isGrouped)

        if (startRowIndex >= 0 && endRowIndex >= 0 && startColumnIndex >= 0 && endColumnIndex >= 0) {
          for (let i = startRowIndex; i < endRowIndex + 1; i++) {
            const row = rows[i]
            for (let j = startColumnIndex; j < endColumnIndex + 1; j++) {
              const column = nonGroupedColumns[j]

              if (
                row &&
                'publicId' in row &&
                column &&
                !(
                  column.column.scriptEnabled ||
                  column.column.formulaEnabled ||
                  column.column.locked ||
                  column.column.isJoined
                )
              ) {
                const validValue = isValidValue(
                  null,
                  row.rowData,
                  spreadsheetData.columnValuesCount,
                  column.column,
                  undefined,
                  spreadsheetData.viewDetails.type
                )

                if (validValue.error) {
                  if (validValue.warn) {
                    setSnackbarMessage({ status: 'warning', message: validValue.errorMessage })
                    newCells.push({
                      columnId: column.column.publicId,
                      rowId: row.publicId,
                      value: null
                    })
                  } else {
                    setSnackbarMessage({ status: 'error', message: validValue.errorMessage })
                  }
                } else {
                  newCells.push({
                    columnId: column.column.publicId,
                    rowId: row.publicId,
                    value: null
                  })
                }
              } else {
                setSnackbarMessage({ status: 'error', message: 'Cannot delete cells in a read only column' })
              }
            }
          }

          // Update the cells locally
          if (newCells.length > 0) {
            setSpreadsheetData({
              type: 'BULK_EDIT_CELL',
              cells: newCells.map((cell) => {
                return { rowId: cell.rowId, columnId: cell.columnId, value: { [cell.columnId]: cell.value } }
              })
            })

            // Send cells to the backend
            const cellUpdates = {
              cells: newCells,
              context: { projectId: project.publicId }
            }

            updateTableViewCellsAction(
              spreadsheetData.viewDetails.publicId,
              cellUpdates,
              spreadsheetData,
              setSpreadsheetData,
              () => {
                return null
              },
              (error) => {
                displayErrorMessage(error)
                return null
              }
            )
          }
        }
      }

      // Undo or redo event
      if (isUndoEvent || isRedoEvent) {
        const historyPoint = isUndoEvent ? spreadsheetData.historyPoint : spreadsheetData.historyPoint + 1
        const newHistoryPoint = isUndoEvent ? historyPoint - 1 : historyPoint

        if (spreadsheetData.history[historyPoint]) {
          const event = spreadsheetData.history[historyPoint]

          // Handle EDIT_CELL event
          if (event && event.type === 'EDIT_CELL' && event.cells) {
            // Update the cells locally
            setSpreadsheetData({
              type: 'BULK_EDIT_CELL',
              cells: event.cells.map((cell: ITableViewHistoryCell) => {
                return {
                  rowId: cell.rowId,
                  columnId: cell.columnId,
                  value: { [cell.columnId]: isUndoEvent ? cell.oldValue : cell.newValue }
                }
              })
            })

            // Move the cell history point
            setSpreadsheetData({
              type: 'UPDATE_HISTORY_POINT',
              point: newHistoryPoint
            })

            // Update the cells
            updateTableViewCellsAction(
              spreadsheetData.viewDetails.publicId,
              {
                cells: event.cells.map((cell: ITableViewHistoryCell) => {
                  return {
                    rowId: cell.rowId,
                    columnId: cell.columnId,
                    value: isUndoEvent ? cell.oldValue : cell.newValue
                  }
                }),
                context: event.context
              },
              spreadsheetData,
              setSpreadsheetData,
              () => {
                return null
              },
              (error) => {
                displayErrorMessage(error)
                return null
              },
              true
            )
          }
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener('copy', handleCopy)
    document.addEventListener('paste', handlePaste)
    document.addEventListener('keydown', handleKeyDownListener)

    return () => {
      document.removeEventListener('copy', handleCopy)
      document.removeEventListener('paste', handlePaste)
      document.removeEventListener('keydown', handleKeyDownListener)
    }
  }, [columns, spreadsheetData, expandedRowsWithGroups, selectedCell, selectedCellRange, isFocused])

  // Update rows if sort settings are changed
  useEffect(() => {
    if (spreadsheetData.loading || spreadsheetData.streaming) {
      return
    }
    setSpreadsheetData({
      type: 'SET_SPREADSHEET_ROWS',
      rows: sortRows(
        spreadsheetData.rows,
        spreadsheetData.userConfiguration.sortSettings,
        spreadsheetData.viewDetails.columns
      ),
      totalRows: spreadsheetData.totalRows
    })
  }, [spreadsheetData.userConfiguration.sortSettings, spreadsheetData.streaming])

  // Listen for changes in view path
  useEffect(() => {
    const viewId = props.tableViewId
    if (viewId && viewId !== spreadsheetData.viewDetails.publicId) {
      setSpreadsheetData({ type: 'SET_VIEW', viewId })
      setSelectedCell(INITIAL_SELECTED_CELL)
      setSelectedCellRange(INITIAL_SELECTED_CELL_RANGE)
    }
  }, [props.tableViewId])

  // Listen for any change in the user selecting a searched cell
  useEffect(() => {
    const searchedCell = spreadsheetData.selectedSearchCell
    if (searchedCell.rowId && searchedCell.columnId) {
      setSelectedCell({
        rowId: searchedCell.rowId,
        columnId: searchedCell.columnId,
        rowNumber: searchedCell.rowNumber,
        columnNumber: searchedCell.columnNumber,
        editing: false,
        value: ''
      })
    }
  }, [spreadsheetData.selectedSearchCell])

  // Update rows if filtering is applied
  useEffect(() => {
    if (spreadsheetData.loading || spreadsheetData.streaming) {
      return
    }
    const { rows, filteredRows } = filterRows(
      spreadsheetData.rows,
      spreadsheetData.columnValuesCount,
      spreadsheetData.filteredRows,
      spreadsheetData.userConfiguration.filterSettings,
      user ? user.email : '',
      user ? user.publicId : '',
      tags ? tags.filter((tag) => tag.projectPublicId === project.publicId) : [],
      spreadsheetData.processTags,
      spreadsheetData.processVariables,
      spreadsheetData.processVariableValues,
      spreadsheetData.viewDetails.columns,
      spreadsheetData.viewDetails.displayValidationErrorRows,
      spreadsheetData.viewDetails.displayCommentRows,
      spreadsheetData.comments
    )
    let sortedRows: ITableRow[] | null = null
    sortedRows = sortRows(rows, spreadsheetData.userConfiguration.sortSettings, spreadsheetData.viewDetails.columns)

    setSpreadsheetData({
      type: 'SET_SPREADSHEET_ROWS',
      rows: sortedRows ? sortedRows : rows,
      totalRows: rows.length,
      filteredRows
    })
  }, [
    spreadsheetData.viewDetails.displayValidationErrorRows,
    spreadsheetData.viewDetails.displayCommentRows,
    spreadsheetData.userConfiguration.filterSettings,
    Object.keys(spreadsheetData.columnValuesCount).length,
    spreadsheetData.streaming,
    user
  ])

  useEffect(() => {
    if (!spreadsheetData.streaming && spreadsheetData.viewDetails.collapsedGroupView) {
      try {
        handleToggleCollapseAll()
      } catch {}
    }
  }, [spreadsheetData.streaming])

  const handleKeyDown = useCallback((e: React.KeyboardEvent, newSelectedCell: ISelectedCell) => {
    // Do not reset selected cell range if copying, pasting or cutting
    if (e.key !== 'Delete' && !e.ctrlKey && !e.metaKey && !e.shiftKey && e.key !== 'Meta') {
      setSelectedCellRange(INITIAL_SELECTED_CELL_RANGE)
    }

    // Disable tab key press so that we don't jump from cell to cell through tab index
    if (e.key === 'Tab') {
      e.preventDefault()
    } else if (KEY_PRESSES.includes(e.key) && (e.ctrlKey || e.metaKey)) {
      e.preventDefault()

      updateSelectedCell((prevSelected) => {
        if (!gridRef.current) {
          return { ...prevSelected }
        }

        let newRowId = prevSelected.rowId
        let newColumnId = prevSelected.columnId
        let newRowNumber = prevSelected.rowNumber
        let newColumnNumber = prevSelected.columnNumber

        switch (e.key) {
          case KEY_PRESS.left:
            gridRef.current.scrollLeft = 0
            newColumnId = newSelectedCell.startColumnId
            newColumnNumber = 0
            break
          case KEY_PRESS.right:
            gridRef.current.scrollLeft = gridRef.current.scrollWidth
            newColumnId = newSelectedCell.finalColumnId
            newColumnNumber = newSelectedCell.finalColumnNumber
            break
          case KEY_PRESS.down:
            gridRef.current.scrollTop = gridRef.current.scrollHeight
            newRowId = newSelectedCell.finalRowId
            newRowNumber = newSelectedCell.finalRowNumber
            break
          case KEY_PRESS.up:
            gridRef.current.scrollTop = 0
            newRowId = newSelectedCell.startRowId
            newRowNumber = 0
            break
        }

        return {
          rowId: newRowId,
          columnId: newColumnId,
          editing: false,
          value: '',
          rowNumber: newRowNumber,
          columnNumber: newColumnNumber
        }
      })
    } else if (KEY_PRESSES.includes(e.key)) {
      e.preventDefault()
      updateSelectedCell((prevSelected) => {
        let newRowId = prevSelected.rowId
        let newColumnId = prevSelected.columnId
        let newRowNumber = prevSelected.rowNumber
        let newColumnNumber = prevSelected.columnNumber

        switch (e.key) {
          case KEY_PRESS.left:
            if (newSelectedCell.prevColumnId !== '') {
              newColumnId = newSelectedCell.prevColumnId
              newColumnNumber -= 1
            }
            break
          case KEY_PRESS.right:
            if (newSelectedCell.nextColumnId !== '') {
              newColumnId = newSelectedCell.nextColumnId
              newColumnNumber += 1
            }
            break
          case KEY_PRESS.down:
            if (newSelectedCell.nextRowId !== '') {
              newRowId = newSelectedCell.nextRowId
              newRowNumber += 1
            }
            break
          case KEY_PRESS.up:
            if (newSelectedCell.prevRowId !== '') {
              newRowNumber -= 1
              newRowId = newSelectedCell.prevRowId
            }
            break
        }

        const cellId = createCellId(newRowId, newColumnId, uniqueNumber)
        const newCell = checkSelectedCellExists(cellId)

        if (newCell) {
          newCell.focus()
          return {
            rowId: newRowId,
            columnId: newColumnId,
            editing: false,
            value: '',
            rowNumber: newRowNumber,
            columnNumber: newColumnNumber
          }
        }

        return { ...prevSelected }
      })
    }
  }, [])

  const handleKeyDownForm = useCallback((e: React.KeyboardEvent, newSelectedCell: ISelectedCell) => {
    // Disable tab key press so that we don't jump from cell to cell through tab index
    if (e.key === 'Tab') {
      e.preventDefault()
    } else if (KEY_PRESSES.includes(e.key)) {
      e.preventDefault()
      updateSelectedCell((prevSelected) => {
        const newRowId = prevSelected.rowId
        const newRowNumber = prevSelected.rowNumber
        let newColumnId = prevSelected.columnId
        let newColumnNumber = prevSelected.columnNumber

        switch (e.key) {
          case KEY_PRESS.left:
          case KEY_PRESS.up:
            if (newSelectedCell.prevColumnId !== '') {
              newColumnId = newSelectedCell.prevColumnId
              newColumnNumber += 1
            }
            break
          case KEY_PRESS.right:
          case KEY_PRESS.down:
            if (newSelectedCell.nextColumnId !== '') {
              newColumnNumber -= 1
              newColumnId = newSelectedCell.nextColumnId
            }
            break
        }

        const cellId = createCellId(newRowId, newColumnId, uniqueNumber)
        const newCell = checkSelectedCellExists(cellId)
        if (newCell) {
          return {
            rowId: newRowId,
            columnId: newColumnId,
            editing: false,
            value: '',
            rowNumber: newRowNumber,
            columnNumber: newColumnNumber
          }
        }

        return { ...prevSelected }
      })
    }
  }, [])

  const frozenLeftOffset =
    lastFrozenColumnIndex >= 0 ? columns[lastFrozenColumnIndex].left + columns[lastFrozenColumnIndex].column.width : 0

  // This useeffect looks for changes in the selected row to see whether to scroll up or down
  useEffect(() => {
    const newRowId = selectedCell.rowId
    if (newRowId !== '' && gridRef.current) {
      let rowNumber
      if (expandedRowsWithGroups && expandedRowsWithGroups.length !== 0) {
        rowNumber = expandedRowsWithGroups.findIndex(
          (row: ITableRow | ITableRowInGroup | IGroupRowCreate | IGroupCollapsed) =>
            'publicId' in row && row.publicId === newRowId
        )
      } else {
        rowNumber = spreadsheetData.rows.findIndex(
          (row: ITableRow | ITableRowInGroup | IGroupRowCreate | IGroupCollapsed) =>
            'publicId' in row && row.publicId === newRowId
        )
      }

      if (rowNumber !== undefined) {
        const rowTop = rowNumber * rowHeight
        const rowBottom = rowTop + rowHeight
        const gridBottom = scrollTop + gridHeight - Number(constants.statsHeight) - Number(constants.headerHeight) - 5
        if (rowBottom > gridBottom) {
          const newPosition = scrollTop + (rowBottom - gridBottom)
          setScrollTop(newPosition)
          verticalScroll(newPosition)
        } else if (rowTop < scrollTop) {
          const newPosition = scrollTop + (rowTop - scrollTop)
          setScrollTop(newPosition)
          verticalScroll(newPosition)
        }
      }
    }
  }, [selectedCell.rowId])

  // This useeffect looks for changes in the selected column to see whether to scroll left or right
  useEffect(() => {
    const newColumnId = selectedCell.columnId

    if (newColumnId !== '') {
      const column = columns.find((column: IViewPortColumn) => column.column.publicId === newColumnId)
      if (column && gridRef.current && !column.isFrozen) {
        const columnWidth = column.column.width
        const columnLeft = column.left
        const columnRight = column.left + columnWidth
        const gridBounds = gridRef.current.getBoundingClientRect()
        const screenLeft = scrollLeft + frozenLeftOffset + Number(constants.rowNumberColumnWidth)
        const screenRight = scrollLeft + gridBounds.width

        const rightDifference = columnRight - screenRight
        const leftDifference = columnLeft - screenLeft

        if (rightDifference > 0) {
          horizontalScroll(scrollLeft + rightDifference + 40)
        } else if (leftDifference < 0) {
          horizontalScroll(Math.max(scrollLeft + leftDifference - 40, 0))
        }
      }
    }
  }, [selectedCell.columnId])

  const setCellValue = useCallback(
    (cells: IUpdateTableViewCell, onSuccess: () => void, onError: (error: any) => void) => {
      updateTableViewCellsAction(
        spreadsheetData.viewDetails.publicId,
        cells,
        spreadsheetData,
        setSpreadsheetData,
        onSuccess,
        onError
      )
    },
    [spreadsheetData.rows, spreadsheetData.viewDetails.columns]
  )

  const handleToggleGroup = useCallback((groupKey: string) => {
    setSpreadsheetData({ type: 'TOGGLE_GROUP_COLLAPSE', groupId: groupKey })
  }, [])

  const getChildKeys = (groupedRows: GroupByDictionary, parentKey: string) => {
    let childKeys: string[] = []
    const groupKeys = Object.keys(groupedRows)

    for (let i = 0; i < groupKeys.length; i++) {
      const key = groupKeys[i]
      childKeys.push(`${parentKey}${GROUP_KEY_SPLIT}${key}`)

      const childGroup = groupedRows[key]
      if (
        childGroup &&
        childGroup.childGroups &&
        typeof childGroup.childGroups === 'object' &&
        !Array.isArray(childGroup.childGroups)
      ) {
        const furtherChildKeys = getChildKeys(childGroup.childGroups, `${parentKey}${GROUP_KEY_SPLIT}${key}`)
        childKeys = childKeys.concat(furtherChildKeys)
      }
    }
    return childKeys
  }

  const handleToggleCollapseAll = () => {
    if (groupedRows) {
      const groupKeys = Object.keys(groupedRows)
      let collapsedGroupIds = groupKeys
      for (let i = 0; i < groupKeys.length; i++) {
        const groupKey = groupKeys[i]
        const group = groupedRows[groupKey]
        if (group && group.childGroups && typeof group.childGroups === 'object' && !Array.isArray(group.childGroups)) {
          const childKeys = getChildKeys(group.childGroups, groupKey)
          collapsedGroupIds = collapsedGroupIds.concat(childKeys)
        }
      }
      setSpreadsheetData({ type: 'COLLAPSE_ALL_GROUPS', groupIds: collapsedGroupIds })
    }
  }

  // Handle creating a new row, this can append a row at the end, or insert a row
  // at an arbitary position
  const handleCreateRow = async (values?: Record<string, ICellValue>, position?: number) => {
    let sortOrder: number | undefined = undefined

    if (position !== undefined) {
      sortOrder = getSortIndexForPosition(spreadsheetData.rows, position)
    }

    const processContext = spreadsheetData.processId
      ? {
          processId: spreadsheetData.processId,
          processSectionId: spreadsheetData.processSectionId!,
          processResponseId: spreadsheetData.processResponseId!
        }
      : undefined

    api
      .createNewRow(
        project.publicId,
        spreadsheetData.viewDetails.publicId,
        values ? convertRowDataToNameValueMap(spreadsheetData.viewDetails.columns, values) : {},
        sortOrder,
        processContext
      )
      .then((response) => {
        // Server returns rowData as name:value, so we transform here to columnId:value
        const createdRow = {
          ...response.data[0],
          rowData: convertRowDataToIdValueMap(spreadsheetData.viewDetails.columns, response.data[0].rowData)
        }

        // Get first non-frozen column
        const viewportColumn = columns.find((c) => c.isFrozen === false && c.isGrouped === false)!

        // If position, we're inserting the row at an arbitary index, otherwise we're just appending
        // it to the end of the rows
        const newPosition = position === undefined ? spreadsheetData.totalRows : position
        setSpreadsheetData({ type: 'INSERT_ROW', row: createdRow, position: newPosition })

        // If we have a viewport column, we want to select the cell in the new row
        if (viewportColumn) {
          setSelectedCell({
            rowId: response.data[0].publicId,
            columnId: viewportColumn.column.publicId,
            columnNumber: viewportColumn.position,
            rowNumber: newPosition,
            editing: false,
            value: ''
          })
        }
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }

  const handleCreateBlankRows = async (numberRows: number, values?: Record<string, ICellValue>) => {
    const processContext = spreadsheetData.processId
      ? {
          processId: spreadsheetData.processId,
          processSectionId: spreadsheetData.processSectionId!,
          processResponseId: spreadsheetData.processResponseId!
        }
      : undefined

    api
      .createBlankRows(project.publicId, spreadsheetData.viewDetails.publicId, numberRows, processContext, values)
      .then((response) => {
        if (response.data) {
          // Add rows to spreadsheet
          for (let i = 0; i < response.data.length; i++) {
            const responseRow = response.data[i]
            const createdRow = {
              ...responseRow,
              rowData: convertRowDataToIdValueMap(spreadsheetData.viewDetails.columns, responseRow.rowData)
            }
            setSpreadsheetData({ type: 'INSERT_ROW', row: createdRow, position: createdRow.sortOrder })
          }
        }
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }

  const visibleColumns = useMemo(() => columns.map((column: IViewPortColumn) => column.column.publicId), [columns])

  return (
    <DataContext.Provider
      value={{
        spreadsheetData,
        setSpreadsheetData,
        selectedCell,
        setSelectedCell,
        selectedCellRange,
        setSelectedCellRange,
        maxHeight,
        verticalScroll,
        horizontalScroll,
        setScrollLeft,
        setScrollTop,
        gridRef,
        handleKeyDown,
        handleKeyDownForm,
        uniqueNumber,
        setCellValue,
        rows,
        groupedRows,
        startRowIndex,
        endRowIndex,
        columns,
        visibleColumns,
        handleToggleGroup,
        handleToggleCollapseAll,
        handleCreateRow,
        handleCreateBlankRows,
        setIsFocused,
        filterMenu,
        setFilterMenu,
        colourMenu,
        setColourMenu
      }}
    >
      {props.children}
    </DataContext.Provider>
  )
}

export const useDataContext = () => {
  const context = useContext(DataContext)

  if (context === undefined) {
    throw new Error('useDataContext must be used witin DataContextProvider')
  }

  return context
}

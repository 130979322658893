import React, { useState } from 'react'
import { IContextMenuState } from 'types'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { PERMISSIONS } from 'helpers/auth'
import { CollapseAll, Visible, Invisible, Dots, ExpandAll, Pencil, Export } from 'components/icons'
import SettingsMenu from 'components/process/components/menu/views/settings'
import SearchMenu from 'components/process/components/menu/views/search'
import AuditModal from 'components/process/components/modal/audit'
import ExportModal from 'components/process/components/modal/export'
import PermissionsModal from 'components/process/components/modal/permissions'
import DefaultVariablesModal from 'components/process/components/modal/variables'
import { useDataContext } from 'components/process/contexts/data'
import useApplicationStore from 'hooks/application'
import RestoreSection from 'components/process/components/modal/restoreSection'
import Duplicate from 'components/duplicate'
import Delete from 'components/delete'
import useProject from 'hooks/project'
import api from 'helpers/api'
import { PROJECT } from 'app-constants'
import DuplicateChildren from 'components/duplicateChildren'
interface ToolbarProps {
  addSection: (parentId: string | null) => void
}

const Toolbar: React.FC<ToolbarProps> = ({ addSection }) => {
  const { project } = useProject()
  const {
    process,
    updateProcess,
    editMode,
    setEditMode,
    navigationMenu,
    setNavigationMenu,
    expandAllSections
  } = useDataContext()
  const { setSnackbarMessage, displayErrorMessage } = useApplicationStore()

  const [settingsMenu, setSettingsMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [searchMenu, setSearchMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [auditModal, setAuditModal] = useState<boolean>(false)
  const [exportModal, setExportModal] = useState<boolean>(false)
  const [restoreSectionModal, setRestoreSectionModal] = useState<boolean>(false)
  const [duplicateModal, setDuplicateModal] = useState<boolean>(false)
  const [archiveModal, setArchiveModal] = useState<boolean>(false)
  const [permissionsModal, setPermissionsModal] = useState<boolean>(false)
  const [defaultVariablesModal, setDefaultVariablesModal] = useState<boolean>(false)
  const [duplicateChildrenModal, setDuplicateChildrenModa] = useState<boolean>(false)
  const [archivingProcess, setArchivingProcess] = useState<{ isArchiving: boolean; status: string | null }>({
    isArchiving: false,
    status: null
  })
  const [allExpanded, setAllExpanded] = useState<boolean>(process && process.expandByDefault ? true : false)

  const settingsWidth = 400
  const searchWidth = 400

  const isAdmin = process && process.permissionLevel === PERMISSIONS.owner

  if (archivingProcess.status) {
    setSnackbarMessage({
      status: archivingProcess.status
    })
    setArchivingProcess({ ...archivingProcess, status: null })
  }

  const archiveProcess = () => {
    if (project && process) {
      api
        .deleteProcess(process.publicId, { context: { projectId: project.publicId } })
        .then(() => {
          updateProcess({ ...process, isDeleted: true, deletedAt: new Date().toISOString() })
        })
        .catch((e) => {
          displayErrorMessage(e)
        })
    }
  }

  if (process) {
    return (
      <div
        className="document-toolbar heavy-shadow"
        style={{
          position: 'fixed',
          inset: '10px 10px auto auto',
          zIndex: 4,
          backgroundColor: 'white',
          fontSize: '90%',
          borderBottom: 'none',
          gap: '0px'
        }}
      >
        {isAdmin && !process.isDeleted && (
          <div
            className={editMode ? 'toolbar-button-active' : 'toolbar-button'}
            title={editMode ? 'Switch To View Mode' : 'Switch To Edit Mode'}
            onClick={() => {
              setEditMode(!editMode)
            }}
          >
            <Pencil />
          </div>
        )}
        <div
          className={navigationMenu ? 'toolbar-button-active' : 'toolbar-button'}
          title={navigationMenu ? 'Hide Navigation Menu' : 'Show Navigation Menu'}
          onClick={() => {
            setNavigationMenu(!navigationMenu)
          }}
        >
          {navigationMenu ? <Invisible /> : <Visible />}
        </div>

        <div
          className="toolbar-button"
          title={`${allExpanded ? 'Collapse' : 'Expand'} All Sections`}
          onClick={() => {
            if (process) {
              if (allExpanded) {
                expandAllSections(process, false)
                setAllExpanded(false)
              } else {
                expandAllSections(process, true)
                setAllExpanded(true)
              }
            }
          }}
        >
          {allExpanded ? <CollapseAll /> : <ExpandAll />}
        </div>
        <div
          className="toolbar-button"
          title={'Export as Word Document'}
          onClick={() => {
            setExportModal(true)
          }}
        >
          <Export />
        </div>
        {isAdmin && (
          <div
            className="toolbar-button"
            title={'Document Settings'}
            onClick={(event: React.MouseEvent) =>
              setSettingsMenu({
                open: true,
                top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                left: `${event.currentTarget.getBoundingClientRect().left - settingsWidth + 20}px`,
                right: 'auto',
                bottom: 'auto'
              })
            }
          >
            <Dots />
          </div>
        )}
        {settingsMenu.open && (
          <SettingsMenu
            id={`settings-process-context-menu`}
            menuState={settingsMenu}
            setMenuState={setSettingsMenu}
            width={settingsWidth}
            setExportModal={(open: boolean) => {
              setExportModal(open)
              if (open) setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
            addSection={addSection}
            setRestoreSectionModal={setRestoreSectionModal}
            setDuplicateModal={setDuplicateModal}
            setArchiveModal={setArchiveModal}
            setAuditModal={setAuditModal}
            setPermissionsModal={setPermissionsModal}
            setDefaultVariablesModal={setDefaultVariablesModal}
            setViewDuplicatesModal={setDuplicateChildrenModa}
          />
        )}

        {searchMenu.open && (
          <SearchMenu
            id={`search-process-context-menu`}
            menuState={searchMenu}
            setMenuState={setSearchMenu}
            width={searchWidth}
          />
        )}
        {auditModal && <AuditModal id="process-audit-modal" open={auditModal} setOpen={setAuditModal} />}
        {exportModal && (
          <ExportModal
            id="export-process-modal"
            open={exportModal}
            setOpen={setExportModal}
            onClose={() => {
              setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)
            }}
          />
        )}
        {archiveModal && (
          <Delete
            id="archive-process-modal"
            resourceName={process.name}
            open={archiveModal}
            setOpen={setArchiveModal}
            deleteResource={() => {
              archiveProcess()
            }}
            otherContent={
              <div style={{ marginTop: '20px' }}>
                Once this document is archived it will be removed from the navigation sidebar. If this document is used
                as the {PROJECT} homepage it may disrupt the functionality of your project.
              </div>
            }
          />
        )}
        {restoreSectionModal && (
          <RestoreSection
            id="restore-section-modal"
            open={restoreSectionModal}
            setOpen={setRestoreSectionModal}
            onClose={() => setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)}
          />
        )}
        {duplicateModal && (
          <Duplicate
            id="duplicate-process"
            type="process"
            publicId={process.publicId}
            open={duplicateModal}
            setOpen={setDuplicateModal}
          />
        )}
        {permissionsModal && (
          <PermissionsModal
            id="permissions-modal"
            open={permissionsModal}
            setOpen={setPermissionsModal}
            onClose={() => setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)}
          />
        )}
        {defaultVariablesModal && (
          <DefaultVariablesModal
            id="default-variables-modal"
            open={defaultVariablesModal}
            setOpen={setDefaultVariablesModal}
            onClose={() => setSettingsMenu(INITIAL_CONTEXT_MENU_STATE)}
          />
        )}
        {duplicateChildrenModal && (
          <DuplicateChildren
            id="duplicate-children-modal"
            open={duplicateChildrenModal}
            setOpen={setDuplicateChildrenModa}
            duplicatedChildren={process.duplicatedChildren ? process.duplicatedChildren : []}
          />
        )}
      </div>
    )
  } else {
    return <div />
  }
}

export default Toolbar
